import { navigate } from "gatsby";
import React, { useEffect } from "react";
import AuthService from "../services/AuthService";

export default function LogoutPage() {

  const load = async () => {
    await AuthService.logout();
    navigate("/");
  };

  useEffect(() => {
    load();
  }, []);

  return <></>;
}
